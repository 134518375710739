<!--
 * @Description: 添加课程资源
 * @Author: xiawenlong
 * @Date: 2021-04-25 09:16:30
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-30 14:35:06
-->

<template>
  <div class="course-resources-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ id ? '编辑课程资源' : '添加课程资源' }}</span>
      </div>
      <el-form ref="form" :model="form" label-width="115px" :rules="rules">
        <el-form-item label="素材名称" prop="videoName">
          <el-input
            v-model="form.videoName"
            placeholder="请输入素材名称4-24个汉字"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="主讲讲师" prop="teacherName">
          <el-input
            v-model="form.teacherName"
            placeholder="请输入讲师名称2-6个汉字"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="素材分组">
          <el-select v-model="form.depts" multiple placeholder="请选择素材分组" clearable>
            <el-option
              v-for="item in deptList"
              :key="item.deptId"
              :label="item.deptName"
              :value="item.deptId"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item ref="coverImageUrl" label="封面图片" prop="coverImageUrl">
          <upload-image
            v-model="form.coverImageUrl"
            drag
            :upload-img="$refs.coverImageUrl"
            :width-size="widthSize"
            :height-size="heightSize"
          ></upload-image>
          <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>
        <el-form-item v-if="!id" label="素材类型" required>
          <el-radio-group v-model="form.materialType">
            <el-radio :label="1">视频</el-radio>
            <!-- <el-radio :label="6">音频</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="!id" label="上传素材" required>
          <el-radio-group v-model="uploadType">
            <el-radio-button label="1">本地上传</el-radio-button>
            <el-radio-button label="2">第三方素材网站</el-radio-button>
          </el-radio-group>
          <div v-if="uploadType == 1">
            <el-upload
              action="/api/third/api/huaWeiUpload/upload/video/local"
              :on-success="handleUploadSuccess"
              :file-list="fileList"
              :limit="1"
              accept=".mp4,.MP4"
            >
              <el-button>选择上传素材</el-button>
              <p slot="tip" class="tip">
                选择素材后，无需等待在这个页面，点击确定后可以查看上传进度
              </p>
            </el-upload>
          </div>
          <div v-if="uploadType == 2">
            <el-form-item label="素材第三方平台">
              <el-select v-model="form.videoPlatform" placeholder="选择视频平台" clearable>
                <el-option label="华为云" value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="素材地址">
              <el-input v-model="form.videoUrl" placeholder="请输入素材地址" clearable></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submit">保存</el-button>
          <el-button v-if="!id" type="primary" :loading="loading" @click="Surecontinue"
            >确定并继续添加</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { courseVideoAdd, courseVideoUpdate, uploadMetaDataUrl, videoInfo } from '@/api/course'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
export default {
  name: 'CourseResourcesAdd',
  components: { UploadImage },
  data() {
    return {
      widthSize: 564,
      heightSize: 290,
      rules: {
        videoName: [
          { required: true, message: '请输入素材名称', trigger: 'blur' },
          { min: 4, max: 24, message: '请输入4~24个字', trigger: 'blur' },
        ],
        teacherName: [{ required: true, message: '请输入讲师名称', trigger: 'blur' }],
        coverImageUrl: [{ required: true, message: '请上传封面图片', trigger: 'change' }],
      },
      form: {
        materialType: 1,
        videoPlatform: '2',
      },
      loading: false,
      uploadType: 1,
      fileList: [],
      id: '',
    }
  },
  mounted() {
    const { id } = this.$route.params
    if (id) {
      this.id = id
      this.getVideoInfo()
    }
  },
  methods: {
    async getVideoInfo() {
      const [res, err] = await to(videoInfo({ id: this.id }))
      if (err) return this.$message.warning(err.msg)
      this.form = res.data
    },
    handleUploadSuccess(response) {
      this.form.videoId = response.data.assetId
      this.$message.success('上传成功')
    },
    async submit() {
      if (this.id) {
        this.update()
      } else {
        if (this.uploadType == 2) {
          await this.urlUpload()
        }
        this.add()
      }
    },
    async Surecontinue() {
      if (this.uploadType == 2) {
        await this.urlUpload()
      }
      this.sure()
    },
    async urlUpload() {
      const [res, err] = await to(
        uploadMetaDataUrl({
          title: this.form.videoName,
          type: '1',
          url: this.form.videoUrl,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      this.form.videoId = res.data.uploadAssetList[0].assetId
    },
    sure() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          const [, err] = await to(courseVideoAdd(this.form))
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('添加成功')
          setTimeout(function() {
            window.location.reload()
          }, 1000)
        }
      })
    },
    add() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          const [, err] = await to(courseVideoAdd(this.form))
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('添加成功')
          this.$router.push('/course/resources')
        }
      })
    },
    update() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          const [, err] = await to(courseVideoUpdate({ id: this.id, ...this.form }))
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('编辑成功')
          this.$router.push('/course/resources')
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.course-resources-add {
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
}
</style>
